define('client/helpers/iptc-check', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.iptcCheck = iptcCheck;
  function iptcCheck(params) {
    if (params[1] == undefined) {
      return false;
    }
    return params[1].includes(params[0]);
  }

  exports.default = Ember.Helper.helper(iptcCheck);
});