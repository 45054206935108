define('client/services/iptc-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
        if (options.url && options.url.startsWith('/v3/api/articles?profile_id') && options.type === 'POST' && options.data && options.data.length > 0) {
          try {
            var requestData = JSON.parse(options.data);

            if (requestData.article_search && requestData.article_search.profile_id) {
              var id = requestData.article_search.profile_id;
              var targetUrl = '/v3/api/articles?profile_id=' + id;
              if (options.url === targetUrl) {
                Ember.run(function () {
                  _this.set("isLoading", true);
                  _this.get("ajax").request("/api/v1/iptc_topics/top_parent_iptc_topics", {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    data: options.data
                  }).then(function (data) {
                    console.log("Data:", data);
                    _this.set('ProfileSelectedIptcIds', data);
                  }).catch(function (error) {
                    throw error;
                  });
                  _this.set("isLoading", false);
                });
              }
            }
          } catch (error) {
            console.error('Error parsing JSON data:', error);
          }
        }
      });
    },
    getProfileSelectedIptcIds: function getProfileSelectedIptcIds() {
      return this.get("ProfileSelectedIptcIds");
    },
    getIsLoading: function getIsLoading() {
      return this.get("isLoading");
    },
    cacheData: function cacheData(iptcTopics) {
      this.set('cachedIptcTopics', iptcTopics.data);
      var iptcLabelMapping = this.buildIptcTopicLabelMapping(this.get('cachedIptcTopics'));
      this.set("iptcTopicLabelMapping", iptcLabelMapping);
    },
    getIptcTopics: function getIptcTopics() {
      var iptcTopics = this.get('cachedIptcTopics');
      var only_top_level = iptcTopics.map(function (iptcTopic) {
        var result = {};
        result['id'] = iptcTopic.id;
        result['label'] = iptcTopic.label;
        result['name'] = iptcTopic.label;
        return result;
      });

      return { all_iptc_topics: iptcTopics, only_top_level: only_top_level };
    },
    getIptcTopicLabelMapping: function getIptcTopicLabelMapping() {
      return this.get('iptcTopicLabelMapping');
    },


    buildIptcTopicLabelMapping: function buildIptcTopicLabelMapping(iptcTopics) {
      var _this2 = this;

      if (iptcTopics) {
        return iptcTopics.reduce(function (result, iptcTopic) {
          result[iptcTopic.id] = iptcTopic.label;
          if (iptcTopic.children) {
            Object.assign(result, _this2.buildIptcTopicLabelMapping(iptcTopic.children));
          }
          return result;
        }, {});
      }
    },

    buildIptcChildren: function buildIptcChildren(iptcTopic) {
      var _this3 = this;

      var result = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      if (iptcTopic == undefined) {
        return result;
      }
      iptcTopic.forEach(function (e) {
        if (e) {
          // Use concat to merge the result array with the IDs of the current level children
          result.push(e.id);
          // Recursively call the function for each child, including nested children
          result = _this3.buildIptcChildren(e.children, result);
        }
      });
      return result;
    },

    selectedIptc: function selectedIptc(iptcTopic, id) {
      for (var k in iptcTopic) {
        if (id == iptcTopic[k].id) {
          return iptcTopic[k];
        } else {
          if (iptcTopic[k].children) {
            // Recursively call the function and return the result
            var result = this.selectedIptc(iptcTopic[k].children, id);
            // If the result is found in the recursive call, return it
            if (result) {
              return result;
            }
          }
        }
      }
    },

    findAncestors: function findAncestors(data, targetId) {
      var path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;

          var currentPath = [].concat(_toConsumableArray(path), [item.id]);
          // debugger
          if (item.id === targetId) {
            return currentPath;
          }

          if (item.children) {
            var result = this.findAncestors(item.children, targetId, currentPath);
            if (result) {
              return result;
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return null;
    },

    iptcPreviousGraphSelection: function iptcPreviousGraphSelection(iptc) {
      this.set("previousIptcGraphData", iptc);
    },

    getiptcPreviousGraphSelection: function getiptcPreviousGraphSelection() {
      return this.get("previousIptcGraphData");
    }
  });
});