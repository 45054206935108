define('client/routes/analyze', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Analyze",

    afterModel: function afterModel() {
      return this.controllerFor('analyze').setupFilterSet(false);
    },


    actions: {
      willTransition: function willTransition(transition) {
        //there are times when we transition to and from analyze
        //coverage and messaging, and want the model in this route to reload
        if (transition.targetName.startsWith('analyze.') && !transition.targetName.includes('chart')) {
          this.controllerFor('analyze').setupFilterSet(false, false);
        } else {
          this.controller.set('showSearchAndFilter', false);
        }
      }
    }
  });
});