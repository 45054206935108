define('client/services/iptc-topics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    iptcTopicNames: function iptcTopicNames() {
      return this.get("ajax").request("/api/v1/iptc_topics", {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }).then(function (data) {
        console.log("Data:", data);
        return data;
      }).catch(function (error) {
        throw error;
      });
    },
    topIptcTopics: function topIptcTopics(requestBody) {
      return this.get("ajax").request("/api/v1/iptc_topics/top_parent_iptc_topics", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: requestBody
      }).then(function (data) {
        console.log("Data:", data);
        return data;
      }).catch(function (error) {
        throw error;
      });
    }
  });
});