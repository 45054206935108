define('client/router', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route("sales", function () {
      this.route("new");
      this.route("edit", { path: "/:id" });
      this.route("duplicate", { path: "/:id/duplicate" });
    });

    this.route('reports', function () {
      this.route("share", { path: "/shared/:hex" }, function () {
        this.route("page", { path: "/pages/:page" });
      });
      this.route("new", { path: "/new" }, function () {
        this.route("preview", { path: "/preview/:id" });
        this.route("create", { path: "/form/:id" });
        this.route("create-blank", { path: "/form" });
      });
      this.route("show", { path: "/:id" }, function () {
        this.route("page", { path: "/pages/:page" });
      });
    });

    this.route('report_not_found', { path: 'report_not_found' });

    this.route("dashboard", { path: "/" }, function () {
      this.route("demo", { path: "/dashboard-demo" });
      this.route("embed", { path: "/embed/:token/profile/:profile_id" });
    });

    this.route("research", function () {
      this.route('trends', function () {
        this.route("author", { path: "/author/:id" });
        this.route("publication", { path: "/publication/:id" });
      });
      this.route('authors', function () {
        this.route("author", { path: "/author/:id" });
        this.route("publication", { path: "/publication/:id" });
      });
      this.route('publications', function () {
        this.route("author", { path: "/author/:id" });
        this.route("publication", { path: "/publication/:id" });
      });
    });

    this.route("insights", function () {
      this.route("author", { path: "/author/:id" });
      this.route("publication", { path: "/publication/:id" });
    });

    this.route("plan", function () {
      this.route("campaigns", function () {
        this.route('new');
        this.route('edit', { path: '/:campaign_id' });
      });
      this.route("neo", function () {
        this.route("buckets", function () {
          this.route("new");
          this.route("show", { path: "/:id" });
        });
        this.route("soundbytes", function () {
          this.route("new");
          this.route("edit", { path: "/:id/edit" });
        });
      });
      this.route("media", function () {
        this.route("new");
        this.route("edit", { path: "/:id/edit" });
      });
      this.route("pitch", function () {
        this.route("new");
        this.route("edit", { path: "/:id/edit" });
        this.route("show", { path: "/:id" }, function () {
          this.route("author", { path: "/author/:author_id" });
        });
      });
    });

    this.route("analyze", function () {
      this.route("coverage", function () {
        this.route("chart", { path: "/chart/:type" }, function () {
          this.route("author", { path: "/author/:author_id" });
          this.route("email", { path: "/email" });
        });
        this.route("author", { path: "/author/:id" });
        this.route("email", { path: "/email" });
      });
      this.route("messaging");
    });

    this.route("promote", function () {
      this.route("articles");
      this.route("analytics");
      this.route("impact");
      this.route("pixels");
      this.route("link-helper");
      this.route("info");
    });

    this.route("settings", function () {
      this.route("user-management");

      this.route("notifications", function () {});
      this.route("user", function () {});
      this.route("company", function () {});
      this.route("competitors", function () {});
      this.route("owned-media-channels", function () {});
      this.route("crawler-search-terms", function () {});

      this.route("search-seeds");

      this.route("impact-score-settings", function () {});

      this.route("analytics-integration", function () {});
      this.route("social-media-integration", function () {});
      this.route("pr-performance-metrics", function () {});
      this.route("added-articles", function () {});
      this.route("excluded-articles", function () {});
      this.route("excluded-campaign-articles", function () {}), this.route("tag-management", function () {});
      this.route("export-management", function () {});
      this.route('excluded-domains', function () {});

      this.route('custom-colors', function () {});
    });
    this.route('not-found', { path: '/*:' });
    this.route('not-found', { path: '404' });
  });

  exports.default = Router;
});