define('client/mixins/models/dashboard-template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var IDENTIFIER_TO_FEATURE_MAP = {
    reach: "showReach",
    owned_media_readership: "showReadership",
    owned_media_impact: "showReadership",
    power_of_voice_sentiment: "hasPOV2Sentiment",
    pov_over_time_sentiment: "hasPOV2Sentiment",
    tone_brand: "hasArticleSentiment"
  };

  exports.default = Ember.Mixin.create({
    profileService: Ember.inject.service("profile"),
    profile: Ember.computed.alias("profileService.profile"),

    shouldShow: Ember.computed("identifier", 'profile.{' + Object.values(IDENTIFIER_TO_FEATURE_MAP).uniq().join(",") + '}', function () {
      var _getProperties = this.getProperties("profile", "identifier"),
          profile = _getProperties.profile,
          identifier = _getProperties.identifier,
          profileFeature = IDENTIFIER_TO_FEATURE_MAP[identifier];

      if (profileFeature) {
        return profile.get(profileFeature);
      }
      return true;
    })
  });
});